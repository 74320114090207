import React, { useCallback, useEffect, useRef, useState } from 'react';
import SlideToggle from '../ReactToggleSlider/ReactSlideToggle.umd';
import dynamic from 'next/dynamic';
import { getAnchorIdFromEntity, stringifyAnchor, useAnchor } from '../../adapters/helpers/Anchor';
import { toggleScroll} from '../../adapters/helpers/Utils';
import Block from '../../adapters/helpers/Block';
const Icon = dynamic(() => import('../Icon/Icon'))
import PropTypes from 'prop-types';
import { mediaQueryHOC } from '../../adapters/helpers/Hooks'
import { LanguageSelectionConstants } from '../../adapters/helpers/Constants'
import {Label} from '../Label/Label'
import { AnalyticsConstants } from '../../adapters/helpers/ConstantsGA';

const primaryColor = LanguageSelectionConstants.primaryColor;

function LanguageSelection(props) {
    const block = new Block(props);

    const anchor = useAnchor();
    const overlayToggleRef = useRef();
    const languageSelectorRef = useRef();
    const linksRef = useRef();

    const [isLanguageSelectorOpen, setLanguageSelectorOpen] = useState(false);
    const [lastScroll, setLastScroll] = useState(0);

    const handleExpanding = useCallback(
        () => { setLanguageSelectorOpen(true); },
        []
    );

    const handleCollapsing = useCallback(
        () => { setLanguageSelectorOpen(false); },
        []
    );

    const handleClickOutside = useCallback(
        (event) => {
            if (overlayToggleRef.current && languageSelectorRef.current && !languageSelectorRef.current.contains(event.target)) {
                overlayToggleRef.current.toggle();
            }
        },
        []
    );

    const trapFocusInOverlay = event => {
        if (!isLanguageSelectorOpen) {
            return;
        }
        const element = event.currentTarget;
        const focusableElements = element.querySelectorAll('button:not([disabled]), a[href]:not([disabled])');
        const firstFocusableElement = focusableElements?.[0];
        const lastFocusableElement = focusableElements[focusableElements.length - 1];
        const KEYCODE_TAB = 9;

        element.addEventListener('keydown', function(e) {
            var isTabPressed = (e.key === 'Tab' || e.keyCode === KEYCODE_TAB);
        
            if (!isTabPressed) { 
              return; 
            }
        
            if ( e.shiftKey ) /* shift + tab */ {
              if (document.activeElement === firstFocusableElement) {
                lastFocusableElement.focus();
                  e.preventDefault();
                }
              } else /* tab */ {
              if (document.activeElement === lastFocusableElement) {
                firstFocusableElement.focus();
                  e.preventDefault();
                }
              }
          });
    };

    const handleScroll = useCallback(
        () => {
            if (!document.body.classList.contains('noScroll')) {
                setLastScroll(window.pageYOffset);
            }
        }
    );

    useEffect(
        () => {
            if (isLanguageSelectorOpen) {
                window.addEventListener('mousedown', handleClickOutside);
                toggleScroll(isLanguageSelectorOpen, lastScroll);

                return () => {
                    window.removeEventListener('mousedown', handleClickOutside);
                }
            } else {
                toggleScroll(isLanguageSelectorOpen, lastScroll);
            }
        },
        [isLanguageSelectorOpen]
    );

    useEffect(
        () => {
            window.addEventListener('scroll', handleScroll);
            return () => {
                window.removeEventListener('scroll', handleScroll);
            }
        },
        [lastScroll]
    );

    useEffect(() => {
        if (languageSelectorRef?.current) {
            languageSelectorRef.current.addEventListener(LanguageSelectionConstants.keydown, trapFocusInOverlay);
        if(isLanguageSelectorOpen){   
            return () => {
                languageSelectorRef.current.removeEventListener(LanguageSelectionConstants.keydown, trapFocusInOverlay)
                }
            }
        }
    },[isLanguageSelectorOpen]);

    const scrollTo = (event) => {
        event.preventDefault();

        if (languageSelectorRef.current) {
            const currentTarget = event.currentTarget;
            const anchorId = currentTarget.dataset.anchorId
            const element = languageSelectorRef.current.querySelector(`[data-id=${anchorId}]`);
            element.scrollIntoView({behavior: LanguageSelectionConstants.smooth});
        }
    };

    const calculateHeight = () => {
        const announcementBar = document.querySelector('.ob-announcement-bar');
        const overlay = languageSelectorRef.current.querySelector('.ob-language-overlay');
        const header = document.querySelector('.zone-header');

        if (props.isMedium) {
            if (announcementBar && announcementBar.style.display !== 'none') {
                linksRef.current.style.height = `calc(${window.innerHeight}px - ${linksRef.current.offsetTop + announcementBar.offsetHeight}px)`
                overlay.style.height = `calc(${window.innerHeight}px - ${overlay.offsetTop + announcementBar.offsetHeight}px)`
            } else {
                linksRef.current.style.height = `calc(${window.innerHeight}px - ${linksRef.current.offsetTop}px)`
                overlay.style.height = `calc(${window.innerHeight}px - ${overlay.offsetTop}px)`
            }
        } else {
            if (header) {
                overlay.style.height = `calc(${window.innerHeight}px - ${overlay.offsetTop}px)`;
                linksRef.current.style.height = `${window.innerHeight - (linksRef.current.offsetTop + header.offsetHeight)}px`
            }
        }
    };

    // This is the label displayed and also serves as the CTA to trigger the language menu
    const currentLanguage = block.getFieldValue(LanguageSelectionConstants.currentLanguage);
    const title = block.getFieldValue(LanguageSelectionConstants.title);
    const regions = block.getFieldValue(LanguageSelectionConstants.regions);

    const jumpToCopy = Label({label: block.getFieldValue(LanguageSelectionConstants.jumpToLabel)});
    const closeButtonCopy = Label({label: block.getFieldValue(LanguageSelectionConstants.closeLanguageSelectionLabel)});

    const LanguageSelectionAriaLabel = block.getFieldValue(LanguageSelectionConstants.countryLanguageAriaLabel)

    return (
        <SlideToggle
            ref={overlayToggleRef}
            duration={500}
            collapsed
            onExpanding={handleExpanding}
            onCollapsing={handleCollapsing}
            onExpanded={calculateHeight}
        >
            {({ onToggle, setCollapsibleElement, toggleState }) => (
                <div className='ob-language-selector' ref={languageSelectorRef} onKeyDown={(event) => {
                    if (event.key === 'Escape' && (toggleState === 'EXPANDED' || toggleState === 'EXPANDING')) {
                        onToggle();
                    }
                }}>
                    <button className='event_button_click ob-language-selector-toggle'
                        aria-label={LanguageSelectionAriaLabel}
                        data-action-detail={AnalyticsConstants.countrySelectorText} onClick={() => {
                        onToggle();
                        calculateHeight();
                    }}>
                        {currentLanguage}
                        <Icon className='ob-language-selector-toggle-icon' name={LanguageSelectionConstants.wideChevronDown} size='0.8' />
                    </button>

                    <div role="menuitem" className='language-overlay ob-language-overlay'
                         ref={setCollapsibleElement}
                         style={{display: 'none'}}
                     >
                        <div className='ob-language-overlay-inner'>
                            <div className='ob-language-overlay-header'>
                                <h2 className='ob-language-overlay-title'>{title}</h2>
                            </div>

                            <div className='ob-language-overlay-tabs'>
                                <div className='ob-language-overlay-tabs-desktop'>
                                    <ul className='mini-nav-regions ob-language-overlay-tabs'>
                                        {regions.map((region, index) => (
                                            <li key={`region-nav-${index}`}>
                                                 <h3><a aria-label={region.fields.name} className='event_internal_link'
                                                   data-action-detail={getAnchorIdFromEntity(region)}
                                                   href={`#${getAnchorIdFromEntity(region)}-${index}`}
                                                   data-anchor-id={getAnchorIdFromEntity(region)}
                                                   onClick={scrollTo}
                                                >
                                                    {region.fields.name}
                                                </a></h3>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>

                            <div className='ob-language-overlay-links' ref={linksRef}>
                                <div className='ob-language-overlay-tabs'>
                                    <SlideToggle duration={500} collapsed>
                                        {({ onToggle, setCollapsibleElement, toggleState }) => (
                                            <div className='ob-language-overlay-tabs-mobile'>
                                                <button aria-label={jumpToCopy} className='ob-language-overlay-tabs-toggle' onClick={onToggle}>
                                                    {jumpToCopy}
                                                    <span className='ob-language-overlay-tabs-toggle--icon'>
                                                        {toggleState === 'EXPANDED' || toggleState === 'EXPANDING' ? (
                                                            <Icon name='chevronTop' color='white' size={2} />
                                                        ) : (
                                                            <Icon name='chevronDown' color='white' size={2} />
                                                        )}
                                                    </span>
                                                </button>

                                                <div ref={setCollapsibleElement}
                                                    className='ob-language-overlay-tabs-mobile-inner'
                                                    style={{display: 'none'}}>
                                                    <ul className='mini-nav-regions'>
                                                        {regions.map((region, index) => (
                                                            <li key={'region-nav-' + index}>
                                                                <a aria-label={region.fields.name} className='event_internal_link'
                                                                   data-action-detail={getAnchorIdFromEntity(region)}
                                                                   href={stringifyAnchor({
                                                                       ...anchor,
                                                                       region: getAnchorIdFromEntity(region),
                                                                   })}
                                                                   data-anchor-id={getAnchorIdFromEntity(region)}
                                                                   onClick={scrollTo}
                                                                >
                                                                    {region.fields.name}
                                                                </a>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        )}
                                    </SlideToggle>
                                </div>
                                <ul className='market-selection'>
                                    {regions.map((region, regionIndex) => (
                                        <li key={'region-' + regionIndex}>
                                            <div className="market-selection-title" data-id={getAnchorIdFromEntity(region)}>
                                                {region.fields.name}
                                            </div>

                                            <ul>
                                                {region.fields.markets.map((market, marketIndex) => (
                                                    <li key={'market-' + marketIndex}>
                                                        <a aria-label={market.fields.nativeLanguageLabel} className='event_internal_link'
                                                           data-action-detail={market.fields.nativeLanguageLabel}
                                                           href={market.fields.link}>
                                                            {market.fields.nativeLanguageLabel}
                                                        </a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            <button className='event_close_window ob-language-overlay-close-btn' onClick={onToggle} aria-label={closeButtonCopy}>
                                <Icon name={LanguageSelectionConstants.close} size='2.4' color={primaryColor} arialable={closeButtonCopy} />
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </SlideToggle>
    );
}

export default mediaQueryHOC(LanguageSelection)
// This export is for unit testing (do not remove) :
export const LanguageSelectionTest = LanguageSelection;

LanguageSelection.propTypes = {
    isMedium: PropTypes.bool,
};
